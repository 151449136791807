﻿/*global
jQuery,
EJS,
window,
todaysRaces,
todaysCards,
utils,
onResizeBrowser,
clientObject,
isPriorityCard,
GBEUtils,
openToteboardPopup,
videoStreams
*/

(function ($) {

    "use strict";

    function showFilteredProbables(instance, poolId) {
        $(".gep-probablesTab", instance).hide();
        $("#probablesTab-" + poolId, instance).show();
    }

    const allPoolsTabIndex = 1;
    const probablesTabIndex = 2;
    const willPaysTabIndex = 3;

    let clientObject;
    let utils;
    let onResizeBrowser;

    const methods = {

        init: function (clientObjectToUse, utilsToUse, onResizeBrowserToUse, options) {

            const instance = this;

            clientObject = clientObjectToUse;
            utils = utilsToUse;
            onResizeBrowser = onResizeBrowserToUse;

            $(instance).data("options", options);

            // Refresh toteboard every N seconds
            setInterval(function () {

                const currentRace = $(instance).data("currentRace");

                // Only refresh toteboard if currently shown
                if ($(instance).is(":visible")) {
                    if (currentRace !== undefined) {
                        options.onRefresh(currentRace);
                    }
                }

            }, ((clientObject.settings.general.toteboardPollingPeriod || 30) * 1000));

            return this;
        },

        hide: function () {
            const instance = this;
            $("#gep-info-pools", instance).hide();
        },

        updateTrack: function (races) {
            const instance = this;
            const options = this.data("options");
            let nextRace;

            // Sort races by raceNumber
            races.sort(utils.comparers.compareRaceNumber);

            // Work out the next race for the track
            $.each(races, function () {
                // First race with a status of 'Future' is the next race
                if (nextRace === undefined && this.raceStatus === 1) {   // 1 === Future
                    nextRace = this;
                }
            });

            // In case all races are finished (or cancelled), default to last race
            if (nextRace === undefined) {
                nextRace = races[races.length - 1];
            }

            $(instance).removeData("currentRace");

            // Update race info for new track/race
            options.onRaceChange(nextRace);

            return this;
        },
        showLoading: function () {

            // Hide all content and show 'spinner' icon, regardless of which section of toteboard is active

            const instance = this;

            $("#gep-racetab-pools .gep-pools", instance).empty();
            $("#gep-racetab-pools .gep-loader", instance).show();

            $("#gep-racetab-pools-all .gep-pools", instance).empty();
            $("#gep-racetab-pools-all .gep-loader", instance).show();

            $("#gep-racetab-probables .gep-probables", instance).empty();
            $("#gep-racetab-probables .gep-loader", instance).show();

            $("#gep-racetab-willpays .gep-willpays", instance).empty();
            $("#gep-racetab-willpays .gep-loader", instance).show();

        },
        updateRace: function (p) {
            const instance = this;
            const options = this.data("options");
            let activeTabIndex = 0;
            let thisCard;
            let cards;
            let races;

            $(instance).data("currentRace", p.race);

            // Remember currently active tab (if any)
            if ($("#gep-poolTabs", this).hasClass("ui-tabs")) {
                activeTabIndex = $("#gep-poolTabs", this).tabs("option", "active");
            }

            $(this).html($.trim(new EJS({ element: "template_infoTabs_race" }).render({
                showToteboardPopupLink: p.showToteboardPopupLink,
                maxNumberOfOpenPopups: clientObject.settings.toteboardPopup.maxNumberOfOpenPopups,
                clientObject: clientObject
            })));

            p.reviewEnablingOfToteboardPopupLinks();

            $(".gep-popout", instance).click(() => openToteboardPopup(
                instance,
                p.race
            ));

            $(".gep-tabs", this).tabs({
                activate: function () {
                    onResizeBrowser();
                }
            });

            // Set active tab
            $("#gep-poolTabs", this).tabs("option", "active", activeTabIndex);

            // Disable Probables and Will Pays by default, they will be enabled if there is data to show
            $("#gep-poolTabs", this).tabs("disable", 2);
            $("#gep-poolTabs", this).tabs("disable", 3);

            thisCard = p.todaysCards.getCardByShortName(p.race.iTSPEventCode);

            // Make array of cards, sort by name, and move NYRA card to start of array
            cards = p.todaysCards.getCards(clientObject.settings.priorityCards.shortCardNames);

            $.each(cards, function () {
                $(".gep-tracks", instance).append($("<option "
                    + (utils.isPriorityCard(this.shortCardName, clientObject.settings.priorityCards.shortCardNames) ? "class='gep-card-highlighted'" : "")
                    + "/>").text(this.cardName).val(this.cardId));
            });

            races = p.todaysRaces.getRacesForCard(thisCard.cardId);

            $.each(races, function () {
                $(".gep-races", instance).append($("<option />").text("R" + this.raceNumber).val(this.raceId).data("race", this));
            });
            $(".gep-races", instance).val(String(p.race.raceId));

            $(".gep-tracks", instance).bind("change", function () {
                options.onTrackChange(Number($(this).val()));
            }).val(thisCard.cardId);

            $(".gep-races", instance).bind("change", function () {
                $(instance).removeData("currentRace");
                options.onRaceChange($(this).find(":selected").data("race"));
            }).val(p.race.raceId);

            return this;
        },
        updatePools: function (pools, race) {

            const instance = this;
            const currentRace = $(instance).data("currentRace");
            let indexedPools = {};
            let activeTabIndex;
            let allPools;

            // Make sure raceId matches race currently shown (to cope with near simultaneous auto-refresh
            // of current race and user changing what is current race)
            if (!currentRace || race.raceId !== currentRace.raceId) {
                return;
            }

            // Convert array of pools into associative array indexed by poolTypeCode
            $.each(pools, function () {
                indexedPools[this.poolTypeCode] = this;
            });

            $("#gep-racetab-pools .gep-loader", this).hide();
            $("#gep-racetab-pools .gep-pools", this).html($.trim(new EJS({
                element: "template_infoTabs_pools"
            }).render({
                indexedPools: indexedPools,
                race: race,
                myFormatter: clientObject.myFormatter,
                clientObject: clientObject
            })));

            allPools = race.pools.filter(function (pool) {
                return pool.raceSummaries[0].raceId === race.raceId && clientObject.settings.showTotalsFor.poolTypeCodes.indexOf(pool.poolTypeCode) > -1;
            }).sort(function (a, b) {
                return clientObject.settings.showTotalsFor.poolTypeCodes.indexOf(a.poolTypeCode) > clientObject.settings.showTotalsFor.poolTypeCodes.indexOf(b.poolTypeCode) ? 1 : -1;
            });

            $("#gep-racetab-pools-all .gep-loader", this).hide();
            $("#gep-racetab-pools-all .gep-pools", this).html($.trim(new EJS({
                element: "template_infoTabs_pools_all"
            }).render({
                pools: allPools,
                myFormatter: clientObject.myFormatter,
                clientObject: clientObject
            })));

            // Check just in case toteboard popup has been closed before this code is executed
            if ($("#gep-poolTabs", instance).data("ui-tabs")) {

                if (allPools.length === 0) {
                    $("#gep-poolTabs", instance).tabs("disable", allPoolsTabIndex);

                    // If currently on "All Pools" tab, switch to first tab "WPS Pools"
                    activeTabIndex = $("#gep-poolTabs", instance).tabs("option", "active");
                    if (activeTabIndex === allPoolsTabIndex) {
                        $("#gep-poolTabs", instance).tabs("option", "active", 0);
                    }
                } else {
                    $("#gep-poolTabs", instance).tabs("enable", allPoolsTabIndex);
                }
            }

            return this;
        },
        updateProbablesSubmenuOptions: function (pools) {

            const instance = this;

            $("#gep-poolTypeDropdown", instance).append("<div class=\"gep-submenu\" style=\"display:none;\"></div>");
            $("#gep-poolTypeDropdown .gep-submenu", instance).empty();

            $.each(pools, function () {
                const pool = this;
                if ((pool.probables.length > 0) && (pool.probables[0].selection.indexOf(",") > -1)) {

                    $("#gep-poolTypeDropdown .gep-submenu", instance).append($("<a href=\"javascript:void(0);\"></a>").text(pool.poolTypeName).click(function () {

                        $("#gep-poolTabs", instance).tabs("option", "active", probablesTabIndex);

                        // Record pool currently shown on Probables tab
                        $(instance).data("currentProbablesPoolId", pool.poolId);

                        showFilteredProbables(instance, pool.poolId);

                        $("#gep-poolTypeDropdown .gep-submenu", instance).hide();
                    }));
                }
            });
        },
        updateProbables: function (pools, raceId) {

            const instance = this;
            const currentRace = $(instance).data("currentRace");
            let activeTabIndex;
            let poolProbableCount;
            let firstProbablesPoolId;
            let currentProbablesPoolIdPresent;

            // Make sure raceId matches race currently shown (to cope with near simultaneous auto-refresh
            // of current race and user changing what is current race)
            if (!currentRace || raceId !== currentRace.raceId) {
                return;
            }

            poolProbableCount = 0;
            currentProbablesPoolIdPresent = false;
            $.each(pools, function () {
                if ((this.probables.length > 0) && (this.probables[0].selection.indexOf(",") > -1)) {

                    poolProbableCount = poolProbableCount + 1;

                    if (this.poolId === Number($(instance).data("currentProbablesPoolId"))) {
                        currentProbablesPoolIdPresent = true;
                    }

                    if (firstProbablesPoolId === undefined) {
                        firstProbablesPoolId = this.poolId;
                    }
                }
            });

            if (!currentProbablesPoolIdPresent) {
                $(instance).data("currentProbablesPoolId", firstProbablesPoolId);
            }

            // Check just in case toteboard popup has been closed before this code is executed
            if ($("#gep-poolTabs", instance).data("ui-tabs")) {

                if (poolProbableCount === 0) {
                    // No probables available for this race

                    // Disable "Probables" tab
                    $("#gep-poolTabs", instance).tabs("disable", probablesTabIndex);

                    // If currently on "Probables" tab, switch to first tab "Pools"
                    activeTabIndex = $("#gep-poolTabs", instance).tabs("option", "active");
                    if (activeTabIndex === probablesTabIndex) {
                        $("#gep-poolTabs", instance).tabs("option", "active", 0);
                    }

                } else {
                    $("#gep-poolTabs", instance).tabs("enable", probablesTabIndex);
                }
            }


            $("#gep-racetab-probables .gep-loader", instance).hide();

            $("#gep-racetab-probables .gep-probables", instance).html($.trim(new EJS({
                element: "template_infoTabs_probables"
            }).render({
                pools: pools,
                poolIdToShow: Number($(instance).data("currentProbablesPoolId")),
                utils: utils,
                myFormatter: clientObject.myFormatter,
                clientObject: clientObject
            })));

            $("#gep-racetab-probables .gep-tabs", instance).tabs();

            $("#gep-racetab-probables .gep-popout", instance).bind("click", function () {

                const content = $(this).parent().parent().html();
                let dialog;

                dialog = $("<div />").html(content).dialog({
                    title: clientObject.resources.poolsProbablesWillpays,
                    autoOpen: true,
                    width: 900,
                    height: 800,
                    resize: "auto",
                    modal: true,
                    resizeStop: function () {
                        jQuery(this).dialog("option", "position", "center");
                    },
                    close: function () {
                        $(this).dialog("destroy").remove();
                    }
                });

                $(".gep-expand", dialog).remove();
                $(".gep-tabs", dialog).tabs();

            });

            $("#gep-poolTypeDropdown", instance).hover(
                function () {
                    $(".gep-submenu", this).show();
                },
                function () {
                    $(".gep-submenu", this).hide();
                }
            );

            onResizeBrowser();

        },
        updateWillPays: function (willPays, raceId, runners) {
            const instance = this;
            const currentRace = $(instance).data("currentRace");

            let activeTabIndex;

            // Make sure raceId matches race currently shown (to cope with near simultaneous auto-refresh
            // of current race and user changing what is current race)
            if (!currentRace || raceId !== currentRace.raceId) {
                return;
            }

            if (willPays.length === 0) {

                // No will pays available for this race

                // Disable "Will Pays" tab

                // Check just in case toteboard popup has been closed before this code is executed
                if ($("#gep-poolTabs", instance).data("ui-tabs")) {

                    $("#gep-poolTabs", instance).tabs("disable", willPaysTabIndex);

                    // If currently on "Will Pays" tab, switch to first tab "Pools"
                    activeTabIndex = $("#gep-poolTabs", instance).tabs("option", "active");

                    if (activeTabIndex === willPaysTabIndex) {
                        $("#gep-poolTabs", instance).tabs("option", "active", 0);
                    }
                }

            } else {

                $("#gep-racetab-willpays .gep-loader", instance).hide();

                $("#gep-racetab-willpays .gep-willpays", this).html($.trim(new EJS({
                    element: "template_infoTabs_willpays"
                }).render({
                    willPays: willPays,
                    runners: runners,
                    myFormatter: clientObject.myFormatter,
                    clientObject: clientObject
                })));

                $("#gep-poolTabs", instance).tabs("enable", willPaysTabIndex);
            }
        }
    };


    $.fn.gep_infoTabsToteboard = function (method, ...otherArguments) {

        // Method calling logic
        if (methods[method]) {
            return methods[method].apply(this, otherArguments);
        }

        if (typeof method === "object" || !method) {
            return methods.init.apply(this, [method].concat(otherArguments));
        }


        $.error("Method " + method + " does not exist on jQuery.gep_infoTabsToteboard");
    };

}(jQuery));
